import { Autocomplete } from "@mui/material";
import styled from "styled-components";

export const AutoComplateStyled = styled(Autocomplete)<any>`
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
    background: ${({ theme }) => theme.colors.bg.whiteBg};
    .MuiOutlinedInput-root.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      padding: 0;
      .MuiOutlinedInput-input.MuiInputBase-input {
        width: 100% !important ;
        /* min-width: 300px !important ; */
        padding: 15px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.border.grey} !important;
      border-radius: 8px;
      padding: 15px;
    }
  }
  &.error {
    .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.border.red} !important;
      }
    }
  }
`;
