import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ChangeLanguageStyled } from "./ChangeLanguage.styles";
import { useTranslation } from "react-i18next";
import browserStorage from "services/storage/browserStorage";
import { LANGUAGES } from "./ChangeLanguage.constants";

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedLang = event.target.getAttribute("data-language");
    i18n.changeLanguage(selectedLang);
    setAnchorEl(null);
  };

  return (
    <ChangeLanguageStyled>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {browserStorage.get("i18nextLng")}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: -4,
        }}
      >
        <MenuItem onClick={handleClose} data-language={LANGUAGES.uz}>
          UZ
        </MenuItem>
        <MenuItem onClick={handleClose} data-language={LANGUAGES.ru}>
          RU
        </MenuItem>
      </Menu>
    </ChangeLanguageStyled>
  );
};

export default ChangeLanguage;
