import React from "react";

const BellIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 12.75C17.25 13.2 16.95 13.5 16.5 13.5H1.5C1.05 13.5 0.75 13.2 0.75 12.75C0.75 12.3 1.05 12 1.5 12C2.325 12 3 11.325 3 10.5V6.75C3 3.45 5.7 0.75 9 0.75C12.3 0.75 15 3.45 15 6.75V10.5C15 11.325 15.675 12 16.5 12C16.95 12 17.25 12.3 17.25 12.75ZM10.95 16.125C10.5 16.875 9.75 17.25 9 17.25C8.625 17.25 8.25 17.175 7.875 16.95C7.5 16.725 7.275 16.5 7.05 16.125C6.825 15.75 6.975 15.3 7.35 15.075C7.725 14.85 8.175 15 8.4 15.375C8.42271 15.3977 8.44542 15.4273 8.47021 15.4596C8.52729 15.534 8.59542 15.6227 8.7 15.675C9.075 15.9 9.525 15.75 9.75 15.375C9.975 15 10.425 14.925 10.8 15.075C11.175 15.225 11.175 15.75 10.95 16.125ZM13.5 10.5C13.5 11.025 13.65 11.55 13.875 12H4.125C4.35 11.55 4.5 11.025 4.5 10.5V6.75C4.5 4.275 6.525 2.25 9 2.25C11.475 2.25 13.5 4.275 13.5 6.75V10.5Z"
        fill="black"
      />
    </svg>
  );
};

export default BellIcon;
