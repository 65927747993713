import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import EditIcon from "assets/EditIcon";
import { IEditButtonProps } from "./EditButton.types";
import { IconButtonStyled } from "./EditButton.style";
const EditButton: FC<IEditButtonProps> = ({ to }) => {
  const navigate = useNavigate();
  const editDataGoPath = () => {
    if (to) {
      navigate(to);
    }
  };
  return (
    <div onClick={editDataGoPath}>
      {/* <Link to={to} className="text-decoration-none"> */}
      <Tooltip title="Edit">
        <IconButtonStyled>
          <EditIcon />
        </IconButtonStyled>
      </Tooltip>
      {/* </Link> */}
    </div>
  );
};

export default EditButton;
