import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { CancelButton, Button } from "../../index";

import DeleteIcon from "assets/DeleteIcon";
import { DeleteButtonPropsType } from "./DeleteButton.types";

import { REQUEST_STATUS, useRequest } from "hooks";
import toast from "react-hot-toast";
import { IconButtonStyled } from "../EditButton/EditButton.style";
import { useTranslation } from "react-i18next";

const DeleteButton: FC<DeleteButtonPropsType> = ({
  backPath = "",
  deleteUrl = "",
  setRender = () => {},
  returnResponseFunction = () => {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [client, data, status, error] = useRequest();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      if (backPath) {
        navigate(backPath);
      }
      returnResponseFunction(data);
      setOpen(false);
      setRender((prev) => !prev);
    }
  }, [status]);

  const deleteElement = async () => {
    if (deleteUrl) {
      await client.deleteRequest(deleteUrl);
    }
  };

  return (
    <div onClick={(e: any) => e.stopPropagation()}>
      <Tooltip title="Delete">
        <div onClick={() => setOpen(true)} className="hover">
          <IconButtonStyled>
            <DeleteIcon width="18px" height="18px" />
          </IconButtonStyled>
        </div>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="px-3 pt-3 mb-2">Rostdan ham o'chirmoqchimisz?</div>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <CancelButton type="button" onClick={() => setOpen(false)} />
            </Grid>
            <Grid item>
              <Button
                value={t("ROLE_FORM.delete")}
                type="button"
                loading={status}
                onClick={deleteElement}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteButton;
