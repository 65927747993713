import styled from "styled-components";
import { Button } from "@mui/material";
import { theme } from "styles/theme/theme";

export const CreateButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.bg.blue} !important;
  padding: 10px 15px !important;
  border-radius: 12px !important;
  text-transform: capitalize !important ;
  color: ${({ theme }) => theme.colors.text.white} !important;
`;
