import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
//@ts-ignore
import { useTranslation } from "react-i18next";

import { MenuStyled, SidebarStyle } from "./Sidebar.style";
import Logo from "./assets/icon/Logo";
import Burger from "./assets/icon/CollapseIcon";
import { SidebarList } from "./SideItems";
import { IPropsT, ISideItemsChildrenT, ISideItemsT } from "./Sidebar.types";
// import Employee from "./";
import "react-pro-sidebar/dist/css/styles.css";
import { isSubMenuActive } from "./utils/isSubMenuActive/isSubMenuActive";
import { isActive } from "./utils/isActive";
import isAdmin from "services/roleAdmin";
import RoleManager from "services/roleManagement";

const Sidebar = ({ collapse, setCollapse }: IPropsT) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasAccess] = RoleManager();

  return (
    <SidebarStyle>
      <ProSidebar collapsed={collapse} breakPoint="md">
        <SidebarHeader>
          <div className="sidebar-header">
            <div className="d-flex align-items-center justify-content-between">
              {!collapse && <Logo />}
              <div
                className={`burger ${collapse ? "rotate-burger" : ""}`}
                onClick={() => setCollapse(!collapse)}
              >
                <Burger />
              </div>
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <MenuStyled iconShape="circle">
            {SidebarList.map((item: ISideItemsT) => {
              return item.children
                ? // <>
                  (!isAdmin || item.id !== "finance") &&
                    (isAdmin || item.id !== "agent") &&
                    item?.children?.find((ch: any) => hasAccess(ch.id)) && (
                      <SubMenu
                        key={item.id}
                        title={t([`SIDEBAR.${item.title}`])}
                        icon={item.icon}
                        className={`  ${
                          isSubMenuActive(
                            item.children,
                            location.pathname,
                            collapse
                          )
                            ? "active"
                            : ""
                        } `}
                      >
                        {item.children.map((children: ISideItemsChildrenT) => {
                          if (hasAccess(children.id)) {
                            return (
                              <MenuItem
                                key={children.id}
                                // active={
                                //   location.pathname === children.path ||
                                //   !!children?.insidePaths?.find(
                                //     (path: string) =>
                                //       path === location.pathname ||
                                //       path === location.pathname.slice(0, -25)
                                //   )
                                // }
                                className={`  ${
                                  isActive(
                                    children.insidePaths,
                                    location.pathname
                                  )
                                    ? "active"
                                    : ""
                                } `}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (location.pathname !== children.path) {
                                    navigate(children.path);
                                  }
                                }}
                              >
                                {t([`SIDEBAR.${children.title}`])}
                              </MenuItem>
                            );
                          }
                        })}
                      </SubMenu>
                    )
                : // </>
                  (!isAdmin || item.id !== "finance") &&
                    (isAdmin || item.id !== "agent") &&
                    hasAccess(item.id) && (
                      <MenuItem
                        key={item.id}
                        className={` ${
                          isActive(item.insidePaths, location.pathname)
                            ? "active"
                            : ""
                        }`}
                        icon={item.icon}
                        // active={
                        //   location.pathname === item.path ||
                        //   !!item?.insidePaths?.find(
                        //     (path: string) =>
                        //       path === location.pathname ||
                        //       path === location.pathname.slice(0, -25)
                        //   )
                        // }
                        onClick={(e) => {
                          e.preventDefault();
                          if (location.pathname !== item.path) {
                            navigate(item.path);
                          }
                        }}
                      >
                        {t([`SIDEBAR.${item.title}`])}
                      </MenuItem>
                    );
            })}
          </MenuStyled>
        </SidebarContent>
        <SidebarFooter className="sidebar-footer py-3">
          <span>
            {t("COMMON.TAXI")}
            {!collapse && "© 2022"}
          </span>
        </SidebarFooter>
      </ProSidebar>
    </SidebarStyle>
  );
};

export default Sidebar;
