// import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbsMUI from "@mui/material/Breadcrumbs";
import LinkMUI from "@mui/material/Link";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BreadCrumbsStyled } from "./BreadCrumbs.styles";

const ID_LENGTH = 24;

const BreadCrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((item) => item);
  let isGetById = false;
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
  };
  if (pathNames[pathNames.length - 1]?.length === ID_LENGTH) {
    isGetById = true;
  }

  return (
    <BreadCrumbsStyled role="presentation" onClick={handleClick}>
      <BreadcrumbsMUI aria-label="breadcrumb">
        {pathNames.map((item, index) => {
          if (isGetById && pathNames.length - 1 === index) {
            return "";
          }
          const navigateTo = pathNames
            .slice(0, pathNames.indexOf(item) + 1)
            .join("/");
          return (
            <LinkMUI
              underline="hover"
              className="breadCrumbLink"
              color={
                pathNames.length - 1 === index ? "text.primary" : "inherit"
              }
            >
              {pathNames.join("/") === navigateTo ||
              (isGetById && pathNames.length - 1 === index + 1) ||
              ["hr", "settings", "cars"].includes(item) ? (
                t(`PATHNAME.${item}`)
              ) : (
                <Link
                  className="router-link"
                  to={pathNames.join("/") === navigateTo ? "" : navigateTo}
                >
                  {t(`PATHNAME.${item}`)}
                </Link>
              )}
            </LinkMUI>
          );
        })}
        {/* <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Core
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          href="/material-ui/react-breadcrumbs/"
          aria-current="page"
        >
          Breadcrumbs
        </Link> */}
      </BreadcrumbsMUI>
    </BreadCrumbsStyled>
  );
};

export default BreadCrumbs;
