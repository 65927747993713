import React from "react";

const CarsIcon = ({ props }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 5.5H2.5C1.96957 5.5 1.46086 5.71071 1.08579 6.08579C0.710714 6.46086 0.5 6.96957 0.5 7.5V11.5H2"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 11.5H13.5V4.5C13.5 4.23478 13.3946 3.98043 13.2071 3.79289C13.0196 3.60536 12.7652 3.5 12.5 3.5H6.5C6.23478 3.5 5.98043 3.60536 5.79289 3.79289C5.60536 3.98043 5.5 4.23478 5.5 4.5V10.18"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 11.5H8.5"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 13.5C5.10457 13.5 6 12.6046 6 11.5C6 10.3954 5.10457 9.5 4 9.5C2.89543 9.5 2 10.3954 2 11.5C2 12.6046 2.89543 13.5 4 13.5Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 13.5C11.6046 13.5 12.5 12.6046 12.5 11.5C12.5 10.3954 11.6046 9.5 10.5 9.5C9.39543 9.5 8.5 10.3954 8.5 11.5C8.5 12.6046 9.39543 13.5 10.5 13.5Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CarsIcon;
