import React, { useMemo } from "react";
// import { useTranslation } from "react-i18next";
import { AppBar, Container, Toolbar } from "@mui/material";
import AvatarComponent from "../Avatar/AvatarComponent";
import { RoundedButton, StyledNavbar, UserStyled } from "./Navbar.style";
// import LanguageSelect from "../LanguageSelect/LanguageSelect";
import browserStorage from "services/storage/browserStorage";
import get from "lodash.get";
import LogOutIcon from "./assets/icon/LogOut";
import { Link, useLocation, useNavigate } from "react-router-dom";
//@ts-ignore
import UserImageDefault from "./assets/icon/user.svg";
import { routes } from "routes/constants/Routes.constants";
import ChangeLanguage from "./components/ChangeLanguage/ChangeLanguage";
import BreadCrumbs from "./components/BreadCrumbs/BreadCrumbs";
import Notifications from "./components/Notifications/Notifications";

const ResponsiveAppBar = ({ collapse }: any) => {
  // const { i18n } = useTranslation();

  const userInfoGetStorage: any = browserStorage.get("admin");
  const userInfo = useMemo(() => {
    if (!!userInfoGetStorage) return JSON.parse(userInfoGetStorage);
    else return {};
  }, [userInfoGetStorage]);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = async () => {
    const localLang: any = await browserStorage.get("i18nextLng");
    await localStorage.clear();
    await browserStorage.set("i18nextLng", localLang);
    navigate("/login");
  };

  return (
    <StyledNavbar collapse={collapse}>
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div className="navbarContent">
              <div className="breadcrumb">
                <BreadCrumbs />
              </div>
              <div className="navbarProfile">
                <Notifications />
                {/* <Link to="/home" className="text-decoration-none m-0 p-0"> */}
                  <UserStyled
                    className={
                      "btn ml-auto mx-3 d-flex align-items-center justify-content-center"
                    }
                  >
                    <img
                      src={
                        // user?.image
                        //   ? process.env.REACT_APP_PRODUCTION_URL + user.image
                        //   :
                        UserImageDefault
                      }
                      alt="User"
                    />
                    <span>
                      {(userInfo?.firstName ? userInfo?.firstName : "") +
                        " " +
                        (userInfo?.lastName ? userInfo?.lastName : "")}
                    </span>
                    {/* <span>Xudayberdiyev Asadbek</span> */}
                  </UserStyled>
                {/* </Link> */}
                <ChangeLanguage />
                {/*<RoundedButton className={"btn mx-3 btn-light ml-auto"}><LockOpen/></RoundedButton>*/}
                <RoundedButton className={"btn ml-auto"} onClick={logout}>
                  <LogOutIcon />
                </RoundedButton>
              </div>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </StyledNavbar>
  );
};
export default ResponsiveAppBar;
