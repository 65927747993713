import React from "react";

const OrderIcon = ({ props }: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8334 17.3334H3.16675C1.78604 17.3334 0.666748 16.2141 0.666748 14.8334V1.50008C0.666748 1.03984 1.03984 0.666748 1.50008 0.666748H13.1667C13.627 0.666748 14.0001 1.03984 14.0001 1.50008V11.5001H17.3334V14.8334C17.3334 16.2141 16.2141 17.3334 14.8334 17.3334ZM14.0001 13.1667V14.8334C14.0001 15.2937 14.3732 15.6667 14.8334 15.6667C15.2937 15.6667 15.6667 15.2937 15.6667 14.8334V13.1667H14.0001ZM4.00008 6.50008V4.83341H10.6667V6.50008H4.00008ZM4.00008 8.16675V9.83342H10.6667V8.16675H4.00008ZM4.00008 13.1667V11.5001H8.16675V13.1667H4.00008Z"
        fill="black"
      />
    </svg>
  );
};

export default OrderIcon;
