import { useRequest } from "hooks";
import { useEffect, useState } from "react";
import browserStorage from "services/storage/browserStorage";

export const useCommon = () => {
  const [render, setRender] = useState<boolean>(false);
  const [updatedOrder, setUpdatedOrder] = useState<any>();
  const [newDriver, setNewDriver] = useState<any>();
  const [photoCheck, setPhotoCheck] = useState<any>();
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [notificationsLength, setNotificationsLength] = useState(
    Number(browserStorage.get("notificationsLength")) || 0
  );
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();
  const [
    getNotificationsClient,
    getNotificationsData,
    getNotificationsStatus,
    getNotificationsError,
  ] = useRequest();

  const [makeReadClient, makeReadData, makeReadStatus, makeReadError] =
    useRequest();

  const admin: any = localStorage.getItem("admin");
  const id: any = JSON.parse(admin)?._id;

  const getRole = async (idDefault: string | undefined) => {
    await getRoleClient.get(`employee/${idDefault || id}`);
  };

  const getNotifications = async (limit: number = 15) => {
    await getNotificationsClient.get(`notification?page=1&limit=${limit}`);
  };

  const makeRead = async (id: string) => {
    await makeReadClient.get(`notification/${id}`);
  };

  useEffect(() => {
    if (id) getRole(undefined);
  }, []);

  return {
    state: {
      createEbookState: {
        getRoleData,
        getRoleStatus,
        getRoleError,
      },
      getNotificationsState: {
        getNotificationsData,
        getNotificationsStatus,
        getNotificationsError,
      },
      makeReadState: {
        makeReadData,
        makeReadStatus,
        makeReadError,
      },
      render,
      updatedOrder,
      newDriver,
      photoCheck,
      notificationsLength,
      notificationOpen,
    },
    actions: {
      getRole,
      setRender,
      setUpdatedOrder,
      setNewDriver,
      setPhotoCheck,
      setNotificationsLength,
      getNotifications,
      setNotificationOpen,
      makeRead,
    },
  };
};
