import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { SaveButtonStyled } from "./Button.style";
import { SaveButtonType } from "./Button.types";
import { REQUEST_STATUS } from "hooks";
import { CircularProgress } from "@mui/material";

const Button: FC<SaveButtonType> = (props) => {
  const {
    onClick,
    type,
    value,
    className,
    symbol = false,
    loading,
    disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <SaveButtonStyled
      className={className}
      variant="contained"
      disabled={loading === REQUEST_STATUS.loading || disabled}
      onClick={onClick}
      type={type}
    >
      {symbol && <> &#43; </>}
      {value || t("BUTTON.SAVE") || "Save"}
      {loading === REQUEST_STATUS.loading && (
        <CircularProgress
          className="loading_animation"
          size="1.8rem"
        />
      )}
    </SaveButtonStyled>
  );
};

export default Button;
