import React from "react";

const AgentsIcon = ({ props }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 21C3 22.2427 4.00725 23.25 5.25 23.25H18.75C19.9927 23.25 21 22.2427 21 21C21 19.2495 21 16.3875 21 14.7518C21 13.6643 20.2223 12.7328 19.1528 12.5385C17.5673 12.2498 14.8335 11.7525 13.287 11.472C12.4358 11.3167 11.5642 11.3167 10.713 11.472C9.1665 11.7525 6.43275 12.2498 4.84725 12.5385C3.77775 12.7328 3 13.6643 3 14.7518V21ZM13.731 13.077L14.8567 17.3573C15.0045 17.919 14.8065 18.5153 14.352 18.8783C13.968 19.1843 13.3628 19.6673 12.9428 20.0033C12.3915 20.4428 11.6085 20.4428 11.0572 20.0033C10.6372 19.6673 10.032 19.1843 9.648 18.8783C9.1935 18.5153 8.9955 17.919 9.14325 17.3573L10.269 13.077L5.11575 14.0137C4.7595 14.079 4.5 14.3895 4.5 14.7518V21C4.5 21.414 4.836 21.75 5.25 21.75C8.388 21.75 15.612 21.75 18.75 21.75C19.164 21.75 19.5 21.414 19.5 21C19.5 19.2495 19.5 16.3875 19.5 14.7518C19.5 14.3895 19.2405 14.079 18.8843 14.0137L13.731 13.077ZM12.1223 12.8573C12.0405 12.855 11.9595 12.855 11.8777 12.8573L10.5998 17.7188L11.9932 18.8302C11.997 18.834 12.003 18.834 12.0068 18.8302L13.4002 17.7188L12.1223 12.8573ZM12 0.75C9.30975 0.75 7.125 2.93475 7.125 5.625C7.125 8.31525 9.30975 10.5 12 10.5C14.6902 10.5 16.875 8.31525 16.875 5.625C16.875 2.93475 14.6902 0.75 12 0.75ZM12 2.25C13.863 2.25 15.375 3.762 15.375 5.625C15.375 7.488 13.863 9 12 9C10.137 9 8.625 7.488 8.625 5.625C8.625 3.762 10.137 2.25 12 2.25Z"
        fill="#000001"
      />
    </svg>
  );
};

export default AgentsIcon;
