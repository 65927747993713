import OrderIcon from "components/shared/Sidebar/assets/icon/OrderIcon";
import HrIcon from "components/shared/Sidebar/assets/icon/HrIcon";
import { ISideItemsT } from "./Sidebar.types";
import AnalyticsIcon from "./assets/icon/AnalyticsIcon";
import AgentsIcon from "./assets/icon/AgentsIcon";
import CarsIcon from "./assets/icon/CarsIcon";
import CitiesIcon from "./assets/icon/CitiesIcon";
import TariffsIcon from "./assets/icon/TariffsIcon";
import SettingsIcon from "./assets/icon/SettingsIcon";
import UserIcon from "./assets/icon/UserIcon";
import CreditCard from "./assets/icon/CreditCard";

export const SidebarList: ISideItemsT[] = [
  {
    id: "analytics",
    path: "/analytics",
    icon: <AnalyticsIcon />,
    title: "ANALYTICS",
    insidePaths: ["/analytics"],
  },
  {
    id: "finance",
    path: "/finance",
    icon: <CreditCard />,
    title: "FINANCE",
    children: [
      {
        id: "card",
        title: "CARD",
        path: "/finance/card",
        insidePaths: ["/finance/card"],
      },
      {
        id: "balance",
        title: "TRANSFER",
        path: "/finance/transfer",
        insidePaths: ["/finance/transfer"],
      },
    ],
  },
  {
    id: "order",
    path: "/orders",
    icon: <OrderIcon />,
    title: "ORDERS",
    insidePaths: ["/orders"],
  },
  {
    id: "hr",
    path: "/hr",
    icon: <HrIcon />,
    title: "HR",
    children: [
      {
        id: "employee",
        title: "EMPLOYEES",
        path: "/hr/employees",
        insidePaths: [
          "/hr/employees",
          "/hr/employees/add",
          "/hr/employees/edit",
        ],
      },
      {
        id: "role",
        title: "ROLES",
        path: "/hr/roles",
        insidePaths: ["/hr/roles", "/hr/roles/add", "/hr/roles/edit"],
      },
      {
        id: "driver",
        title: "DRIVERS",
        path: "/hr/drivers",
        insidePaths: ["/hr/drivers", "/hr/drivers/details"],
      },
    ],
  },
  {
    id: "agent",
    path: "/agents",
    icon: <AgentsIcon />,
    title: "AGENT",
    insidePaths: ["/agents", "/agents/add", "/agents/edit"],
  },
  {
    id: "user",
    path: "/users",
    icon: <UserIcon />,
    title: "USERS",
    insidePaths: ["/users", "/users/add", "/users/edit"],
  },
  {
    id: "car",
    path: "/cars",
    icon: <CarsIcon />,
    title: "CARS",
    children: [
      {
        id: "brand",
        title: "BRANDS",
        path: "/cars/brands",
        insidePaths: ["/cars/brands", "/cars/brands/add", "/cars/brands/edit"],
      },
      {
        id: "model",
        title: "MODELS",
        path: "/cars/models",
        insidePaths: ["/cars/models", "/cars/models/add", "/cars/models/edit"],
      },
    ],
  },
  {
    id: "city",
    path: "/cities",
    icon: <CitiesIcon />,
    title: "CITIES",
    insidePaths: ["/cities", "/cities/add", "/cities/edit"],
  },
  {
    id: "tariff",
    path: "/tariff",
    icon: <TariffsIcon />,
    title: "TARIFFS",
    insidePaths: ["/tariff", "/tariff/add", "/tariff/edit"],
  },
  {
    id: "settings",
    path: "/settings",
    icon: <SettingsIcon />,
    title: "SETTINGS",
    children: [
      {
        id: "settingUpdateMap",
        title: "MAP",
        path: "/settings/map",
        insidePaths: ["/settings/map"],
      },
      {
        id: "settingUpdatePayment",
        title: "PAYMENT",
        path: "settings/payment",
        insidePaths: ["/settings/payment"],
      },
    ],
  },
  // {
  //   id: "hr",
  //   path: "/hr",
  //   icon: <HrIcon />,
  //   title: "HR",
  //   open: false,
  //   children: [
  //     {
  //       id: "roles",
  //       title: "ROLES",
  //       path: "/hr/roles",
  //     },
  //     {
  //       id: "employees",
  //       title: "EMPLOYEES",
  //       path: "/hr/employees",
  //     },
  //   ],
  // },
];
