import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import axios from "services/api/client";

import { REQUEST_STATUS } from "./useRequest.constants";
import { DataType, TApiRequestMetod } from "./useRequest.types";

export const useRequest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<DataType | undefined>();
  const [status, setStatus] = useState<string>(REQUEST_STATUS.initial);
  const [error, setError] = useState<any>();

  const get = async (url: string) => await sendRequest("get", url);

  const post = async (url: string, data: any) =>
    await sendRequest("post", url, data);

  const put = async (url: string, data: any) =>
    await sendRequest("put", url, data);

  const deleteRequest = async (url: string, data: any) =>
    await sendRequest("delete", url);

  const sendRequest = async (
    method: TApiRequestMetod,
    url: string,
    data?: any
  ) => {
    setStatus(REQUEST_STATUS.loading);
    try {
      const res = await axios[method](url, data);
      if (res.status === 401) {
        navigate("/login");
      }
      setData(res.data);
      setStatus(REQUEST_STATUS.success);
      if (method !== "get") {
        if (res.data && url !== "tariffs/price" && url !== "order") {
          toast.success(res?.data?.message);
        }
      }

      return res.data;
    } catch (err: any) {
      if (err?.response?.status === 401) {
        navigate("/login");
      }
      setError(err);
      setStatus(REQUEST_STATUS.failed);
      if (err?.response) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Error");
      }
    }
  };

  return [
    {
      get,
      post,
      put,
      deleteRequest,
    },
    data,
    status,
    error,
  ];
};
