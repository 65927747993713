import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import get from "lodash.get";
import { useTranslation } from "react-i18next";

import { IBasicTableProps } from "./BasicTable.types";
import { REQUEST_STATUS, useRequest } from "hooks";

const BasicTable: React.FC<IBasicTableProps> = ({ columns, dataUrl }) => {
  const [client, res, status, error] = useRequest();
  const [fetchData, setFetchData] = useState<any[]>([]);
  const { t } = useTranslation();

  const fetchDataFunction = async () => {
    if (dataUrl) {
      await client.get(dataUrl);
    }
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      setFetchData(get(res, "data.data"));
    }
  }, [status]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            {columns.map((column: any) => (
              <TableCell align="right">{column?.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchData?.map((item: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="item">
                {index + 1}
              </TableCell>
              {columns.map((column, index) =>
                column.renderItem ? (
                  <TableCell align="right" key={index}>
                    {column.renderItem(item, column, index)}
                  </TableCell>
                ) : (
                  <TableCell align="right" key={index}>
                    {get(item, `${column.dataKey}`)}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!fetchData.length && (
        <p className="text-center my-3">{t('ORDERS.TABLE.DATA_NOT_FOUND')}</p>
      )}
    </TableContainer>
  );
};

export default BasicTable;
