import React, { FC } from "react";
import { CircularProgress } from "@mui/material";

import { REQUEST_STATUS } from "hooks";
import { LoginButtonStyled } from "./LoginButton.styled";
import { ILoginButtonProps } from "./LoginButton.types";

const LoginButton: FC<ILoginButtonProps> = ({
  value,
  type,
  onClick,
  className,
  disabled,
  loading,
}) => {
  return (
    <LoginButtonStyled
      className={className}
      variant="contained"
      disabled={loading === REQUEST_STATUS.loading || disabled}
      onClick={onClick}
      type={type}
    >
      {value}
      {loading === REQUEST_STATUS.loading && (
        <CircularProgress
          className="loading_animation"
          style={{ color: "white" }}
          size="1.8rem"
        />
      )}
    </LoginButtonStyled>
  );
};

export default LoginButton;
