import React, { Suspense, useContext, useEffect, useState } from "react";
import {
  Route,
  Routes as Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import io from "socket.io-client";

import { routes } from "routes/constants/Routes.constants";
import { Navbar, Sidebar } from "components";
import { StyledApp } from "App.style";
import toast, { Toaster } from "react-hot-toast";
import browserStorage from "services/storage/browserStorage";
import { hasToken } from "routes/utils/hasToken/hasToken";
import RoleManager from "services/roleManagement";
import Loader from "pages/loader/container/Loader";
import { CommonContext } from "context";

const Routes = () => {
  const [hasAccess] = RoleManager();
  return (
    <Switch>
      {routes.map((route, index) => (
        <React.Fragment key={index}>
          {hasAccess(route.key) && <Route {...route} />}
        </React.Fragment>
      ))}
    </Switch>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  let browserStorageCollape: any = browserStorage.get("collapse");
  const [collapse, setCollapse] = useState<boolean>(
    browserStorageCollape ? JSON.parse(browserStorageCollape) : false
  );
  const [socket, setSocket] = useState<any>();
  const { pathname } = useLocation();

  const {
    state: { notificationsLength },
    actions: {
      setRender,
      setUpdatedOrder,
      setNotificationsLength,
      getNotifications,
      setNotificationOpen,
    },
  } = useContext(CommonContext);

  useEffect(() => {
    localStorage.setItem("notificationsLength", notificationsLength);
  }, [notificationsLength]);

  useEffect(() => {
    if (!hasToken()) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const newSocket = io("http://jahontaxi.uz", {
      path: "/admin-api/socket.io",
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    socket?.on("connect", () => {});
    socket?.on("newOrder", (data: any) => {
      if (data) {
        setRender((prev: boolean) => !prev);
        if (pathname === "/" || pathname === "/orders") {
          toast.success("Yangi buyurtma qo'shildi", { duration: 3000 });
        }
      }
    });
    socket?.on("updatedOrder", (data: any) => {
      setUpdatedOrder(data?.data);
    });
    socket?.on("photoCheck", (data: any) => {
      setRender((prev: boolean) => !prev);
      getNotifications();
      setNotificationsLength((prev: number) => prev + 1);
    });
    socket?.on("newDriver", (data: any) => {
      setRender((prev: boolean) => !prev);
      getNotifications();
      setNotificationsLength((prev: number) => prev + 1);
    });

    return () => {
      socket?.off("connect");
      socket?.off("newOrder");
      socket?.off("updatedOrder");
      socket?.off("photoCheck");
      socket?.off("newDriver");
    };
  }, [socket]);

  return (
    <>
      <Suspense fallback={<Loader fullWidth size={60} />}>
        <div
          onClick={() => setNotificationOpen(false)}
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Sidebar collapse={collapse} setCollapse={setCollapse} />
          <StyledApp collapse={collapse}>
            <Navbar collapse={collapse} />
            <Suspense fallback={<Loader />}>
              <div style={{ width: "100%", height: "100%" }}>
                <Routes />
              </div>
            </Suspense>
          </StyledApp>
        </div>
      </Suspense>
    </>
  );
};

export default Dashboard;
