import React from "react";
import Error from "../Error/Error";
import { TextAreaStyled, StyledTextArea } from "./TextArea.styles";
import { ITextareaAutosize } from "./TextArea.types";

const TextArea: React.FC<ITextareaAutosize> = ({
  className = "",
  maxRows = 6,
  minRows = 1,
  label = "Text area",
  error,
  params,
}) => {
  return (
    <StyledTextArea>
      <label>{label}</label>
      <TextAreaStyled
        className={`${!!error ? "error" : ""} ${className}`}
        maxRows={maxRows}
        minRows={minRows}
        error={error}
        {...params}
      />
      {!!error && <Error message={error.message} />}
    </StyledTextArea>
  );
};

export default TextArea;
