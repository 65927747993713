import React from "react";

const LogoIcon = (props: any) => {
  return (
    <svg
      width="70"
      height="28"
      viewBox="0 0 70 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H14V14H0V0ZM28 14V28H14V14H28ZM42 14V0H28V14H42ZM56 14V28H42V14H56ZM56 14H70V0H56V14Z"
        fill="#4CE5B1"
      />
    </svg>
  );
};

export default LogoIcon;
