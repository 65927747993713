import React, { useEffect, useState, FC } from "react";
import get from "lodash.get";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableBody } from "@mui/material";
import { REQUEST_STATUS, useRequest } from "hooks";
import { SearchParams } from "services/urlParams/urlParams";
import Loader from "pages/loader/container/Loader";
import { TableFooter, TableHead } from "./components/index";
import {
  StyledTable,
  TableBodyStyled,
  TableCellCheckbox,
  TableContainerStyled,
  TableWrapperStyled,
} from "./Table.style";
import { ITableProps } from "./Table.types";
import { dragLeaveHandler } from "./utils/dragLeaveHandler/DragLeaveHandler";
import { dragEndHandler } from "./utils/dragEndHandler/DragEndHandler";
import { dragOverHandler } from "./utils/dragOverHandler/DragOverHandler";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IOrder } from "pages/hr/driver/details/context/DetailsContext.types";

const Table: FC<ITableProps> = ({
  columns,
  useIdForKey = false,
  onRowClick,
  dataUrl,
  optional,
  selection = false,
  draggable = false,
  draggableChange = () => {},
  handleCheckboxChange = () => {},
  render = false,
  filter = "",
  getData,
  updatedOrder,
}) => {
  const [client, res, status, error] = useRequest();
  const [setParams, getParams] = SearchParams();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [currentItem, setCurrentItem] = useState<any>();
  const [fetchData, setFetchData] = useState<any[]>([]);
  const { search } = useLocation();
  const location = useLocation();
  const { t } = useTranslation();

  // function useQuery() {
  //   const { search } = useLocation();

  //   return React.useMemo(() => new URLSearchParams(search), [search]);
  // }

  // let query = React.useMemo(() => new URLSearchParams(search), [search]);
  // console.log(query);

  useEffect(() => {
    if (updatedOrder) {
      setFetchData((prev) =>
        prev?.map((order: IOrder) => {
          if (order._id === updatedOrder._id) {
            order.state = updatedOrder.state;
          }
          return order;
        })
      );
    }
  }, [updatedOrder]);

  useEffect(() => {
    let newQueryData = {
      limit: getParams().limit ? parseInt(getParams().limit) : 10,
      page: getParams().page ? parseInt(getParams().page) : 1,
    };
    setPage(newQueryData.page);
    setRowsPerPage(newQueryData.limit);
    fetchDataFunction(newQueryData);
  }, [page, rowsPerPage, render, filter]);

  const fetchDataFunction = async ({ limit, page }: any) => {
    setParams({
      ...getParams(),
      ...filter,
      limit,
      page,
    });
    // let request = {
    //   ...getParams(),
    //   limit: parseInt(getParams().limit) === -1 ? 0 : getParams().limit,
    // };
    // console.log(location);
    if (!!dataUrl) {
      await client.get(
        dataUrl +
          `?${
            limit !== -1 && `limit=${limit ? limit : getParams().limit}`
          }&page=${page ? page : getParams().page}${
            optional?.driverId ? `&driverId=${optional?.driverId}` : ""
          }${optional?.userId ? `&userId=${optional?.userId}` : ""}${
            filter.cityId ? `&cityId=${filter.cityId}` : ""
          }${filter.countryId ? `&countryId=${filter.countryId}` : ""}${
            filter.publisherId ? `&publisherId=${filter.publisherId}` : ""
          }${filter.genreId ? `&genreId=${filter.genreId}` : ""}${
            filter.search ? `&search=${filter.search}` : ""
          }${filter.state ? `&state=${filter.state}` : ""}${
            filter.paymentType ? `&paymentType=${filter.paymentType}` : ""
          }${filter.agentId ? `&agentId=${filter.agentId}` : ""}${
            filter.status ? `&status=${filter.status}` : ""
          }`
      );
    }
  };

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      setFetchData(get(res, "data.data"));
      if (getData) getData(get(res, "data.data"));
      if (selection) {
        let defaultSelected: string[] = [];
        get(res, "data.data")?.forEach((item: any) => {
          if (item.isChecked) {
            defaultSelected.push(item._id);
          }
        });
        setSelected(defaultSelected);
      }
    }
  }, [status]);

  useEffect(() => {
    setFetchData(res?.data?.data);
    if (status === REQUEST_STATUS.success) {
      if (getData) getData(res?.data?.data);
    }
  }, [res?.data?.data.length]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setParams({
      ...getParams(),
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (typeof event?.target?.value === "object") {
      setParams({
        ...getParams(),
        page: 1,
        limit: 0,
      });
      setRowsPerPage(0);
    } else {
      setParams({
        ...getParams(),
        page: 1,
        limit: parseInt(event.target.value),
      });
      setRowsPerPage(parseInt(event?.target?.value));
    }
    setPage(1);
  };

  const handleClick = (event: any, _id: string) => {
    let checkboxChangeQuery = {
      checked: event.target.checked,
      _id,
    };
    handleCheckboxChange(checkboxChangeQuery);
    const selectedIndex = selected.indexOf(_id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;

  function dragStartHandler(e: any, item: any) {
    setCurrentItem(item);
  }

  const dropHandler = async (e: any, item: any) => {
    e.preventDefault();
    let filterFetchData = fetchData?.filter(
      (fetchDataItem: any) => fetchDataItem._id !== currentItem._id
    );
    setFetchData(filterFetchData);
    const currentIndex = fetchData?.indexOf(item);
    filterFetchData.splice(currentIndex, 0, currentItem);
    let fetchDataIdes: string[] = [];
    fetchDataIdes = filterFetchData.map((item: any) => item._id);
    await draggableChange({ _id: currentItem._id, position: currentIndex + 1 });
  };

  // if (status === REQUEST_STATUS.loading) return <Loader />;
  if (status === REQUEST_STATUS.failed)
    return <div>{error?.response?.data?.message}</div>;

  const height = document.getElementById("table-top")?.clientHeight;

  return (
    <TableWrapperStyled>
      <TableContainerStyled height={height}>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead columns={columns} selection={selection} />
          <TableBodyStyled>
            {fetchData?.map((item: any, index: number) => {
              const isItemSelected = isSelected(item._id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  role="  "
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={useIdForKey ? item.id : index}
                  onDragOver={(e) => dragOverHandler(e)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDragStart={(e) => dragStartHandler(e, item)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDrop={(e) => dropHandler(e, item)}
                  draggable={rowsPerPage === -1 && draggable}
                  onClick={(event: any) => {
                    onRowClick && onRowClick(item);
                  }}
                  selected={isItemSelected}
                  className={`item ${columns?.length > 1 ? "columns" : ""}`}
                >
                  {selection && (
                    <TableCellCheckbox onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, item._id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCellCheckbox>
                  )}
                  <TableCell key={index}>
                    {index + 1 + (getParams().page - 1) * getParams().limit}
                  </TableCell>
                  {columns.map((column, index) =>
                    column.renderItem ? (
                      <TableCell key={index}>
                        {column.renderItem(item, column, index)}
                      </TableCell>
                    ) : (
                      <TableCell key={index}>
                        {get(item, `${column.dataKey}`)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBodyStyled>
          {status === REQUEST_STATUS.success && !fetchData?.length && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1}>
                  <div className="p-4">{t("ORDERS.TABLE.DATA_NOT_FOUND")}</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {status === REQUEST_STATUS.loading && (
            <TableBody className={"loader-body"}>
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1}>
                  <Loader isTable size={50} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter
            draggable={draggable}
            colSpan={columns.length + 1}
            count={get(res, "data.total")}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </StyledTable>
      </TableContainerStyled>
      <div className="normalize-table"></div>
    </TableWrapperStyled>
  );
};

export default Table;
