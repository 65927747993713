import { useEffect } from "react";
import {
  Route,
  Routes as Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "styled-components";

import { LightTheme } from "styles/theme/lightTheme";
import "styles/index.scss";

import Dashboard from "routes/container/Routes";
import browserStorage from "services/storage/browserStorage";
import Login from "pages/login/container";
import { hasToken } from "routes/utils/hasToken/hasToken";
import CommonProvider from "context/CommonProvider";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/login") {
      if (hasToken()) {
        navigate(-1);
      }
    }
  }, []);

  return (
    <CommonProvider>
      <ThemeProvider theme={LightTheme}>
        <Switch>
          <Route path="*" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
        </Switch>
        <Toaster containerStyle={{ zIndex: "100000" }} />
      </ThemeProvider>
    </CommonProvider>
  );
}

export default App;
