import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoginPageStyle } from "./Login.styled";
import LogoIcon from "../assets/icon/LogoIcon";
import { LoginForm } from "../components";
//@ts-ignore
import LoginImage from "../assets/loginImage.svg";
import { hasToken } from "routes/utils/hasToken/hasToken";

const Login = () => {
  return (
    <LoginPageStyle>
      <Grid container>
        <Grid item md={5} className="loginPageLeft">
          <LogoIcon />
          <LoginForm />
        </Grid>
        <Grid
          item
          md={7}
          className="loginPageRight d-flex align-items-center justify-content-center"
        >
          <img src={LoginImage} alt="LOgin page image" />
        </Grid>
      </Grid>
    </LoginPageStyle>
  );
};

export default Login;
