import { format } from "date-fns";

const DateConvert = () => {
  const dateConvert = (date: string | undefined, timeCheck = false) => {
    if (date) {
      if (timeCheck) {
        return format(new Date(date), "dd.MM.yyyy | HH:mm");
      } else {
        return format(new Date(date), "dd.MM.yyyy");
      }
    } else {
      return "";
    }
  };

  return [dateConvert];
};

export default DateConvert;
