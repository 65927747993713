import { FC } from "react";

import { IInputProps } from "./Input.types";
import { StyledInput } from "./Input.style";
import Label from "../Label/Label";
import Error from "../Error/Error";
import currencyFormatter from "services/number_format/currencyFormatter";

const Input: FC<IInputProps> = ({
  label = "",
  params,
  error,
  disabled = false,
  placeholder = "",
  className = "",
  setValue,
}) => {
  // const currencyFormat = (e: any) => {
  //   setValue(params.name, currencyFormatter(e?.target?.value));
  // };

  return (
    <div className={className}>
      {label && <Label label={label} />}
      <StyledInput
        placeholder={placeholder}
        disabled={disabled}
        onWheel={(e) => e.currentTarget.blur()}
        {...params}
        className={`${!!error ? "error" : ""}`}
        // onChange={setValue ? currencyFormat : params?.onChange}
      />
      {!!error && <Error message={error.message} />}
    </div>
  );
};

export default Input;
