import { FormControlLabel, Switch as MUISwitch } from "@mui/material";
import React, { FC } from "react";
import { SwitchPropsType } from "./Switch.types";
const Switch: FC<SwitchPropsType> = ({
  label = "",
  onChange = () => {},
  checked = false,
  name = "",
  className = "",
  params,
}) => {
  return (
    <FormControlLabel
      className={className}
      control={
        <MUISwitch
          checked={checked}
          onChange={onChange}
          name={name}
          {...params}
        />
      }
      label={label}
    />
  );
};

export default Switch;
