import { lazy } from "react";

import { IRoute } from "../Routes.types";
const Analytics = lazy(() => import("pages/analytics"));
const CardsList = lazy(() => import("pages/finance/card"));
const Transfer = lazy(() => import("pages/finance/transfer"));
const RoleRoutes = lazy(() => import("pages/hr/role"));
const Employees = lazy(() => import("pages/hr/employees"));
const Drivers = lazy(() => import("pages/hr/driver"));
const Agents = lazy(() => import("pages/agents"));
const Users = lazy(() => import("pages/users"));
const Brands = lazy(() => import("pages/cars/brands"));
const Models = lazy(() => import("pages/cars/models"));
const Cities = lazy(() => import("pages/cities"));
const Orders = lazy(() => import("pages/orders"));
const Tariffs = lazy(() => import("pages/tariffs"));
const Map = lazy(() => import("pages/settings/map/container"));
const Payment = lazy(() => import("pages/settings/payment/container"));
const NotFound = lazy(() => import("pages/notFound/container"));

export const routes: IRoute[] = [
  {
    key: "order",
    name: "orders",
    isPrivate: true,
    path: "/",
    element: <Orders />,
  },
  {
    key: "analytics",
    name: "analytics",
    isPrivate: true,
    path: "analytics*",
    element: <Analytics />,
  },
  {
    key: "card",
    name: "card",
    isPrivate: true,
    path: "finance/card/*",
    element: <CardsList />,
  },
  {
    key: "balance",
    name: "transfer",
    isPrivate: true,
    path: "finance/transfer/*",
    element: <Transfer />,
  },
  {
    key: "order",
    name: "orders",
    isPrivate: true,
    path: "orders*",
    element: <Orders />,
  },
  {
    key: "role",
    name: "role",
    isPrivate: true,
    path: "hr/roles/*",
    element: <RoleRoutes />,
  },
  {
    key: "employee",
    name: "employees",
    isPrivate: true,
    path: "hr/employees/*",
    element: <Employees />,
  },
  {
    key: "driver",
    name: "drivers",
    isPrivate: true,
    path: "hr/drivers/*",
    element: <Drivers />,
  },
  {
    key: "agent",
    name: "agents",
    isPrivate: true,
    path: "agents*",
    element: <Agents />,
  },
  {
    key: "user",
    name: "users",
    isPrivate: true,
    path: "users*",
    element: <Users />,
  },
  {
    key: "brand",
    name: "brands",
    isPrivate: true,
    path: "cars/brands/*",
    element: <Brands />,
  },
  {
    key: "model",
    name: "model",
    isPrivate: true,
    path: "cars/models/*",
    element: <Models />,
  },
  {
    key: "city",
    name: "cities",
    isPrivate: true,
    path: "cities*",
    element: <Cities />,
  },
  {
    key: "tariff",
    name: "tariff",
    isPrivate: true,
    path: "tariff*",
    element: <Tariffs />,
  },
  {
    key: "settingUpdateMap",
    name: "map",
    isPrivate: true,
    path: "settings/map",
    element: <Map />,
  },
  {
    key: "settingUpdatePayment",
    name: "payment",
    isPrivate: true,
    path: "settings/payment",
    element: <Payment />,
  },
  {
    key: "notFound",
    name: "notFound",
    isPrivate: false,
    path: "*",
    element: <NotFound />,
  },
];
