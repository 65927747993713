import React, { FC } from "react";
import PlusIcon from "./assets/PlusIcon";

import { CreateButtonStyled } from "./Button.style";
import { IButtonType } from "./Button.types";

const Button: FC<IButtonType> = ({ value, symbol }) => {
  return (
    <CreateButtonStyled
    // {...props}
    // className={className}
    // variant="contained"
    // disabled={disabled}
    // onClick={onClick}
    // type={type}
    >
      {symbol && <PlusIcon />}
      <span className="ms-2">{value}</span>
    </CreateButtonStyled>
  );
};

export default Button;
