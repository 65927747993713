import React from "react";

const PlusIcon = ({ props }: any) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 13.5C4.6925 13.5 2 10.8075 2 7.5C2 4.1925 4.6925 1.5 8 1.5C11.3075 1.5 14 4.1925 14 7.5C14 10.8075 11.3075 13.5 8 13.5ZM8 0C7.01509 0 6.03982 0.193993 5.12987 0.570904C4.21993 0.947814 3.39314 1.50026 2.6967 2.1967C1.29018 3.60322 0.5 5.51088 0.5 7.5C0.5 9.48912 1.29018 11.3968 2.6967 12.8033C3.39314 13.4997 4.21993 14.0522 5.12987 14.4291C6.03982 14.806 7.01509 15 8 15C9.98912 15 11.8968 14.2098 13.3033 12.8033C14.7098 11.3968 15.5 9.48912 15.5 7.5C15.5 6.51509 15.306 5.53982 14.9291 4.62987C14.5522 3.71993 13.9997 2.89314 13.3033 2.1967C12.6069 1.50026 11.7801 0.947814 10.8701 0.570904C9.96018 0.193993 8.98491 0 8 0ZM8.75 3.75H7.25V6.75H4.25V8.25H7.25V11.25H8.75V8.25H11.75V6.75H8.75V3.75Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
