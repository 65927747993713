import { TableHead } from "@mui/material";
import styled from "styled-components";

export const TableHeadStyled = styled(TableHead)`
  white-space: nowrap;
  .MuiTableRow-root.MuiTableRow-head {
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
      color: ${({ theme }) => theme.colors.text.lightBlack} !important ;
    }
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium:last-child {
      text-align: end;
    }
  }
`;
