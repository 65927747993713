import styled from "styled-components";
import { theme } from "./../../../styles/theme/theme";

export const StyledInput = styled.input`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.grey};
  box-sizing: border-box;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.lightBlack};
  padding: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  outline: none;
  transition: all 300ms ease-out;
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.lightBlack};
  }
  &.error {
    border-color: ${({ theme }) => theme.colors.text.red};
  }
`;
