import React from "react";

const AnalyticsIcon = ({ props }: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0.0498047V3.0798C14.39 3.5698 17 6.4698 17 9.9998C17 10.8998 16.82 11.7498 16.5 12.5398L19.12 14.0698C19.68 12.8298 20 11.4498 20 9.9998C20 4.8198 16.05 0.549805 11 0.0498047ZM10 16.9998C8.14348 16.9998 6.36301 16.2623 5.05025 14.9496C3.7375 13.6368 3 11.8563 3 9.9998C3 6.4698 5.61 3.5698 9 3.0798V0.0498047C3.94 0.549805 0 4.8098 0 9.9998C0 12.652 1.05357 15.1955 2.92893 17.0709C3.85752 17.9995 4.95991 18.7361 6.17317 19.2386C7.38642 19.7411 8.68678 19.9998 10 19.9998C13.3 19.9998 16.23 18.3898 18.05 15.9098L15.45 14.3798C14.17 15.9998 12.21 16.9998 10 16.9998Z"
        fill="#313649"
      />
    </svg>
  );
};

export default AnalyticsIcon;
