import { SidebarList } from "components/shared/Sidebar/SideItems";
import { CommonContext } from "context";
import { REQUEST_STATUS, useRequest } from "hooks";
import get from "lodash.get";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import browserStorage from "services/storage/browserStorage";
import { ILoginForm } from "../components/LoginForm/LoginForm.types";
import { IUseLogin } from "./LoginContext.types";

export const useLogin = (): IUseLogin => {
  const {
    state: {},
    actions: { getRole },
  } = useContext(CommonContext);
  const navigate = useNavigate();
  const [loginClient, loginData, loginStatus, loginError] = useRequest();
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();

  const loginFunction = async (loginQuery: ILoginForm) => {
    await loginClient.post("/login", loginQuery);
  };
  const getRoles = async (id: string) => {
    await getRoleClient.get(`role/${id}`);
  };

  const updateWindow = async () => {
    if (loginStatus === REQUEST_STATUS.success) {
      await browserStorage.set("token", get(loginData, "data.token"));
      await browserStorage.set("admin", get(loginData, "data"));
      await getRole(get(loginData, "data")?._id);
      await navigate("/");
    }
  };

  useEffect(() => {
    updateWindow();
  }, [loginStatus]);

  return {
    state: {
      loginState: { loginData, loginStatus, loginError },
      getRoleState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: { loginFunction },
  };
};
