import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import host from "constants/environment";

import browserStorage from "services/storage/browserStorage";

const token = browserStorage.get("token");

function encrypt(date: any) {
  const isoDate = new Date().toISOString();
  const encodedDate = btoa(date);
  return {
    first: isoDate,
    second: encodedDate,
  };
}

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 15000,
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

axios.interceptors.request.use(
  (config) => {
    const token = browserStorage.get("token");
    return {
      ...config,
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 31000,
      headers: {
        Authorization: `Bearer ${token}`,
        test: host,
        ...encrypt(new Date()),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosInstance: AxiosInstance = axios.create(config);

export default axios;
