import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C15.077 12 17.5714 9.50556 17.5714 6.42855C17.5714 3.35153 15.077 0.857117 12 0.857117C8.92299 0.857117 6.42857 3.35153 6.42857 6.42855C6.42857 9.50556 8.92299 12 12 12Z"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5943 23.1428C21.8746 20.8986 20.4608 18.9409 18.5568 17.5519C16.6527 16.163 14.3568 15.4146 12 15.4146C9.64319 15.4146 7.34727 16.163 5.44323 17.5519C3.53919 18.9409 2.12541 20.8986 1.40572 23.1428"
        stroke="#000001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
