import styled from "styled-components";
import { Button } from "@mui/material";
import { theme } from "styles/theme/theme";

export const SaveButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.button.blue} !important;
  padding: 12px 32px !important;
  border-radius: 24px !important;
  max-height: 45.8px !important;
  min-height: 45.8px !important;
  text-transform: capitalize !important;
  position: relative;
  .loading_animation {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
  }
`;
